import Cookies from 'js-cookie'
import { useEffect, useMemo } from 'react'
import useAxios from '@talentinc/gatsby-theme-ecom/hooks/useAxios'
import useLocation from 'react-use/lib/useLocation'
import { EventNames } from '@talentinc/gatsby-theme-ecom/hooks/useStatsEvent'
import {
  CookiesEnum,
  Endpoints,
  MediaQueries,
  reviewPagesSet,
  checkoutPagesSet,
} from '@talentinc/gatsby-theme-ecom/types/constants'

/**
 * We're logging home page and \*-review page visit for all top brands and ZJ.
 * Mobile visit logs both frontend\_visit & frontend\_mobile\_visit.
 * Additionally we're setting two distinct cookies for home page visit and \*-review page visit.
 * Refer to TCP-2997 for reasoning.
 */
export function useLogFrontEndVisit(): void {
  const { pathname: page, hostname: domain, search } = useLocation()
  const axios = useAxios()
  const params = useMemo(
    () => new URLSearchParams(search?.substring(1) ?? ''),
    [search]
  )

  useEffect(() => {
    const pagePath: string = page ?? ''
    if (
      pagePath !== '/' &&
      !reviewPagesSet.has(pagePath) &&
      !checkoutPagesSet.has(pagePath)
    ) {
      return
    }
    const frontendVisitCookie = Cookies.get(CookiesEnum.Frontend24HourVisit)
    const reviewPageVisitCookie = Cookies.get(CookiesEnum.ReviewPage24HourVisit)
    const checkoutPageVisitCookie = Cookies.get(
      CookiesEnum.CheckoutPage24HourVisit
    )
    if (
      (frontendVisitCookie && pagePath === '/') ||
      (reviewPageVisitCookie && reviewPagesSet.has(pagePath)) ||
      (checkoutPageVisitCookie && checkoutPagesSet.has(pagePath))
    ) {
      return
    }

    let eventType = window.matchMedia(MediaQueries.TabletAndBelow).matches
      ? EventNames.mobileFrontendVisit
      : EventNames.frontendVisit
    if (checkoutPagesSet.has(pagePath)) {
      eventType = window.matchMedia(MediaQueries.TabletAndBelow).matches
        ? EventNames.mobileCheckoutVisit
        : EventNames.checkoutVisit
    }

    const promises = [
      axios.post(
        Endpoints.Stats,
        {
          event: eventType,
          page: pagePath,
          referrer: document.referrer,
        },
        { params }
      ),
    ]
    Promise.all(promises)
      .then((response) => {
        if (pagePath === '/') {
          Cookies.set(CookiesEnum.Frontend24HourVisit, '1', {
            expires: 1,
            domain,
            path: pagePath,
          })
        } else if (reviewPagesSet.has(pagePath)) {
          Cookies.set(CookiesEnum.ReviewPage24HourVisit, '1', {
            expires: 1,
            domain,
            path: pagePath,
          })
        } else if (checkoutPagesSet.has(pagePath)) {
          Cookies.set(CookiesEnum.CheckoutPage24HourVisit, '1', {
            expires: 1,
            domain,
            path: pagePath,
          })
        }
        return
      })
      .catch(() => {
        return
      })
  }, [axios, domain, page, params])
}
